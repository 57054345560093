.hero{
    background: url('../../assets/mainBG.png') repeat fixed 100%;
    max-width: 100%;
    height: 145em;
    z-index: 9999;
    position: relative;
}

.hero-container{
    max-width: 100%;
    margin: 15rem 17rem;
    position: absolute;
}

.hero-content{
    max-width: 100%;
}

.premier h1{
    text-transform: uppercase;
    max-width: 100%;
}

.premier>:nth-child(3){
    font-weight: 400;
}

.hero-content button{
    width: 17em;
    padding: 1rem;
    margin-top: 3rem;
    box-shadow: 0 0 10px var(--themeColor);
}

.ammar{
    margin-top: 3rem;
    color: var(--white);
    line-height: 27px;
}

.ammar h3{
    text-transform: uppercase;
    font-size: 1rem;
}

.ammar>:nth-child(2){
    color: var(--themeColor);
    text-transform: capitalize;
}

.hero-divider{
    border: 1px solid var(--themeColor);
    width: 9%;
    margin: 1rem 0;
}

.explore{
    margin: auto;
    max-width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.explore h3{
    margin-top: 43%;
    color: var(--white);
    font-size: 1rem;
}

@media screen and (max-width: 680px) {

    .hero{
        max-width: 100%;
        position: relative;
        height: 100%;
    }

    .premier h1{
        font-size: 1rem;
        margin-left: 3rem;
    }

    .hero-content button{
        font-size: .5rem;
        margin-left: 3rem;
        margin-top: 1rem;
        padding: .5rem;
    }

    .ammar{
        margin-left: 3rem;
    }

    .ammar h3{
        font-size: .6rem;
        margin-top: -.8rem;
    }

    .hero-divider{
        margin: auto 0;
    }

    .ammar p{
        font-size: .6rem;
    }

    .explore h3{
        margin-top: 90%;
        font-size: .6rem;
    }

    .explore img{
        width: 1.5rem;
    }
}

@media screen and (max-width: 1440px) {

    .explore h3{
        margin-top: 46%;
    }

    .premier{
        margin-top: 12rem;
    }

    .premier h1{
        font-size: 2.5rem;
    }

    .hero-content button{
        margin: 1.5rem 0;
    }

    .ammar{
        margin: 1rem 0;
    }

    .ammar h3{
        font-size: 1rem;
    }

    .explore h3{
        font-size: .8rem;
    }

    .explore img{
        width: 2rem;
    }
}

@media screen and (max-width: 1600px){
    .hero{
        height: 145em;
        width: 100%;
    }

    .premier{
        font-size: 1rem;
        margin-top: 8rem;
    }

    
}



