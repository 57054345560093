.cloudsbg{
    position: absolute;
}

.cloudsbg img{
    max-width: 100%;
}

@media screen and (max-width: 680px) {
    .cloudsbg{
        position: absolute;
        margin-top: 11rem;
    }
}

@media screen and (max-width: 1440px) {
    .cloudsbg{
        margin-top: 18rem;
    }
}

@media screen and (max-width: 1600px) {
    .cloudsbg{
        margin-top: 18rem;
    }
}